<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="banner-container d-md-none">
      <div class="image-banner"></div>
      <div class="title-yeah">YEAH</div>
      <div class="subtitle-regular">{{ $t('roommates-special-powers') }}</div>
      <div class="subtitle-large">
        <div>{{ $t('take-advantage') }}</div>
        <div>{{ $t('of-mega-offers') }}</div>
      </div>
      <div class="subtitle-regular">
        <div>{{ $t('of-selected-products') }}</div>
        <div>{{ $t('from-our-marketplace') }}</div>
      </div>
      <div class="button-products">
        <router-link :to="{name: 'MarketplaceCategoryPage', params: {id: 'promo'}}">
          <Button :text="$t('all-promo-products')" :theme="'solid-white'" :size="'medium'" :svg="'orange-arrow-right'"/>
        </router-link>
      </div>
    </div>
    <div class="banner-container-desktop d-none d-md-flex">
      <div class="image-banner"></div>
      <div class="svg-house-overlay">
        <svg width="548" height="574" viewBox="0 0 548 574" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.2" d="M548 553.508V140.324C548 140.324 548 140.324 548 137.963C546.073 133.241 542.22 128.519 538.367 123.797L274.413 -112.309L272.487 -114.67C266.707 -117.031 262.853 -119.392 259 -119.392C255.147 -119.392 251.293 -117.031 247.44 -117.031L245.513 -114.67L-18.44 111.991C-22.2933 114.352 -26.1467 119.074 -28.0733 126.158C-28.0733 126.158 -28.0733 126.158 -28.0733 128.519C-28.0733 128.519 -28.0733 128.519 -28.0733 130.88C-28.0733 130.88 -28.0733 130.88 -28.0733 133.241C-26.1467 140.324 -22.2933 147.407 -18.44 152.129C-12.66 156.851 -6.88 159.212 -1.1 159.212H139.547V421.289C139.547 440.178 153.033 456.705 168.447 456.705H364.967C380.38 456.705 393.867 440.178 393.867 421.289V253.654C393.867 234.766 380.38 218.239 364.967 218.239H245.513C230.1 218.239 216.613 234.766 216.613 253.654C216.613 272.543 230.1 289.07 245.513 289.07H336.067V385.873H195.42V140.324C195.42 121.435 181.933 104.908 166.52 104.908H87.5267L259 -41.4772L490.2 159.212V518.092H27.8V270.182C27.8 251.293 14.3133 234.766 -1.1 234.766C-16.5133 234.766 -30 251.293 -30 270.182V553.508C-30 572.397 -16.5133 588.924 -1.1 588.924H517.173C534.513 588.924 548 572.397 548 553.508Z" fill="#EEF1F3"/>
        </svg>
      </div>
      <div class="col-6">
        <div class="subtitle-regular">{{ $t('roommates-special-powers') }}</div>
        <div class="subtitle-large">
          <div>{{ $t('take-advantage') }}</div>
          <div>{{ $t('of-mega-offers') }}</div>
        </div>
        <div class="subtitle-regular">
          {{ $t('of-selected-products') }} {{ $t('from-our-marketplace') }}
        </div>
        <div class="button-products col-md-7 col-xxl-6">
          <router-link :to="{name: 'MarketplaceCategoryPage', params: {id: 'promo'}}">
            <Button :text="$t('all-promo-products')" :theme="'solid-white'" :size="'medium'" :svg="'orange-arrow-right'"/>
          </router-link>        </div>
      </div>
      <div class="title-yeah col-6 float-left">YEAH</div>
    </div>
  </div>

</template>

